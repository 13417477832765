import React from "react";
import PropTypes from "prop-types";

function Logo(props) {
  const { size, color } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <path d="M26.61 0.14L27.89 0.31L29.15 0.55L30.38 0.86L31.58 1.22L32.76 1.65L33.91 2.14L35.03 2.68L36.11 3.28L37.16 3.93L38.17 4.63L39.14 5.39L40.08 6.19L40.97 7.03L41.81 7.92L42.61 8.86L43.37 9.83L44.07 10.84L44.72 11.89L45.32 12.97L45.86 14.09L46.35 15.24L46.78 16.42L47.14 17.62L47.45 18.85L47.69 20.11L47.86 21.39L47.96 22.68L48 24L47.96 25.32L47.86 26.61L47.69 27.89L47.45 29.15L47.14 30.38L46.78 31.58L46.35 32.76L45.86 33.91L45.32 35.03L44.72 36.11L44.07 37.16L43.37 38.17L42.61 39.14L41.81 40.08L40.97 40.97L40.08 41.81L39.14 42.61L38.17 43.37L37.16 44.07L36.11 44.72L35.03 45.32L33.91 45.86L32.76 46.35L31.58 46.78L30.38 47.14L29.15 47.45L27.89 47.69L26.61 47.86L25.32 47.96L24 48L22.68 47.96L21.39 47.86L20.11 47.69L18.85 47.45L17.62 47.14L16.42 46.78L15.24 46.35L14.09 45.86L12.97 45.32L11.89 44.72L10.84 44.07L9.83 43.37L8.86 42.61L7.92 41.81L7.03 40.97L6.19 40.08L5.39 39.14L4.63 38.17L3.93 37.16L3.28 36.11L2.68 35.03L2.14 33.91L1.65 32.76L1.22 31.58L0.86 30.38L0.55 29.15L0.31 27.89L0.14 26.61L0.04 25.32L0 24L0.04 22.68L0.14 21.39L0.31 20.11L0.55 18.85L0.86 17.62L1.22 16.42L1.65 15.24L2.14 14.09L2.68 12.97L3.28 11.89L3.93 10.84L4.63 9.83L5.39 8.86L6.19 7.92L7.03 7.03L7.92 6.19L8.86 5.39L9.83 4.63L10.84 3.93L11.89 3.28L12.97 2.68L14.09 2.14L15.24 1.65L16.42 1.22L17.62 0.86L18.85 0.55L20.11 0.31L21.39 0.14L22.68 0.04L24 0L25.32 0.04L26.61 0.14ZM12.69 35.31L13.59 36.15L14.55 36.91L15.57 37.6L16.65 38.21L17.77 38.74L18.95 39.18L20.16 39.53L21.41 39.79L22.69 39.95L24 40L25.31 39.95L26.59 39.79L27.84 39.53L29.05 39.18L30.23 38.74L31.35 38.21L32.43 37.6L33.45 36.91L34.41 36.15L35.31 35.31L36.15 34.41L36.91 33.45L37.6 32.43L38.21 31.35L38.74 30.23L39.18 29.05L39.53 27.84L39.79 26.59L39.95 25.31L40 24L39.95 22.69L39.79 21.41L39.53 20.16L39.18 18.95L38.74 17.77L38.21 16.65L37.6 15.57L36.91 14.55L36.15 13.59L35.31 12.69L35.2 12.59L12.59 35.2L12.69 35.31Z" />
    </svg>
  );
}

Logo.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
};

export default Logo;
