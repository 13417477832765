import React from "react";
import PropTypes from "prop-types";

function Batu(props) {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
    >
      <path d="M38 12.83L35.17 10 24 21.17 12.83 10 10 12.83 21.17 24 10 35.17 12.83 38 24 26.83 35.17 38 38 35.17 26.83 24z" />
    </svg>
  );
}

Batu.propTypes = {
  size: PropTypes.number.isRequired
};

export default Batu;
